<template>
  <div>
    <ProfileForm :errors="violations" :values="item" :loading="isLoading" :handle-reset="resetForm"
                 :handle-submit="onSendForm" ref="createForm"/>
    <Loading :visible="isLoading"/>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {createHelpers} from 'vuex-map-fields';
import ProfileForm from '../../components/profile/Form';
import Loading from '../../components/Loading';
import CreateMixin from '../../mixins/CreateMixin';
import has from 'lodash/has';

const servicePrefix = 'Profile';

const {mapFields} = createHelpers({
  getterType: 'profile/getField',
  mutationType: 'profile/updateField'
});

export default {
  name: 'ProfileCreate',
  servicePrefix,
  mixins: [CreateMixin],
  components: {
    Loading,
    ProfileForm
  },
  data() {
    return {
      item: {},
    }
  },
  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },
  methods: {
    ...mapActions('profile', ['create', 'resetData']),
    afterCreate() {
      this.$store.commit('authentication/profileDataSuccess', this.created);
    }
  },
  watch: {
    item() {
      if (!has(this.item, 'backendUser') && !has(this.item, 'backendUser')) {
        this.item[this.userData.type] = this.userData.iri;
      }
    }
  }
}
</script>
